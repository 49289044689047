/**
 * create by wangchunyan1 on 2019/6/24
 */

/*
* 表示在window运行环境，而非gatsby编译环境
* */
export function isInWindow() {
  return typeof window !== `undefined`;
}

/*
* 加载url,选中tab
* */
export function currentUrlToActiveTab(url) {
  let item = "";
  if (url.includes("home")) {
    item = "home";
  } else if (url.includes("teacher")) {
    item = "teacher";
  } else if (url.includes("master")) {
    item = "master";
  } else if (url.includes("operation")) {
    item = "operation";
  } else if (url.includes("news")) {
    item = "news";
  } else if (url.includes("download")) {
    item = "download";
  }
  return item;
}


/*
 * 验证输入的是数字
 * */
export function checkoutNumber(value) {
  let reg = /(^$)|(^[0-9]\d*$)/;
  return reg.test(value);
}


/*
 * 验证手机号
 * */
export function checkoutPhone(value) {
  let reg = /^1[3456789]\d{9}$/;
  return reg.test(value);
}


/*
* 获取设备
* */

/*获取用户设备信息*/
export function getTerminalType() {
  let terminalType = "pc";
  let sUserAgent = navigator.userAgent.toLowerCase();
  let bIsIpad = sUserAgent.includes("ipad");
  let bIsIphoneOs = sUserAgent.includes("iphone os");
  let bIsMidp = sUserAgent.includes("midp");
  let bIsUc7 = sUserAgent.includes("rv:1.2.3.4");
  let bIsUc = sUserAgent.includes("ucweb");
  let bIsAndroid = sUserAgent.includes("android");
  let bIsCE = sUserAgent.includes("windows ce");
  let bIsWM = sUserAgent.includes("windows mobile");
  if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
    terminalType = "yd";
  }
  return terminalType;
}

/*对象拼接为字符串，方便两种接口调用*/
export function formatParams(obj) {
  return Object.keys(obj).map(function(prop) {
    return encodeURIComponent(prop) + "=" + encodeURIComponent(obj[prop]);
  }).join("&");
}

/*
* 返回角度
* */
export function getAngle(dy, dx) {
  return Math.atan2(dy, dx) * 180 / Math.PI;
}

/*
* 返回滑动方向
* */
export function getDirection(startX, endX, startY, endY) {
  let dx = endX - startX;
  let dy = endY - startY;
  let result = 0;
  if (Math.abs(dx) < 30 && Math.abs(dy) < 30) {
    return result;
  }
  let angle = getAngle(dy, dx);
  if (angle >= -45 && angle < 45) {
    result = 4;//从左往右滑动
  } else if (angle >= 45 && angle < 135) {
    result = 1;
  } else if (angle >= -135 && angle < -45) {
    result = 2;
  } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
    result = 3;//从右往左滑动
  }
  return result;
}
