import React from "react";
// import Helmet from "react-helmet";

// import { Link, graphql } from "gatsby";
import { isInWindow,getTerminalType } from "../utils/tools";

class IndexPage extends React.Component {

  componentWillMount() {
    if (isInWindow()) {//防止在编译的时候执行
      if (getTerminalType() === "yd") {
        window.location.href = "/homey";
      } else {
        window.location.href = "/homep";
      }
    }
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}

export default IndexPage;
